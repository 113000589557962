import React, { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from 'framer-motion'
import styles from './style.module.css'



interface Props {
  children: JSX.Element;
  width?: "fit-content" | "100%",
}



const Reveal = ({ children, width = "fit-content" }: Props) => {
  const ref = useRef(null)
  // const isInView = useInView(ref, { once: true })
  // const mainControls = useAnimation()
  // const slideControls = useAnimation()
  // useEffect(() => {
  //   if (isInView) {
  //     mainControls.start("visible")
  //     slideControls.start("visible")
  //   }
  // }, [isInView])
  return (
    <>
      <div
        ref={ref}
        style={{ position: "relative", overflow: "hidden" }}>
        <motion.div
          className={styles.animate_title}
        // variants={{
        //   hidden: { opacity: 0, y: 75 },
        //   visible: { opacity: 1, y: 0 },
        // }}
        // initial="hidden"
        // animate={mainControls}
        // transition={{ duration: 1, delay: 0.30 }}
        >
          {children}
        </motion.div>
        {/* <motion.div
          variants={{
            hidden: { left: 0 },
            visible: { left: "100%" },
          }}
          initial="hidden"
          animate={slideControls}
          transition={{ duration: 1, ease: "easeIn" }}
          style={{
            position: "absolute",
            top: 4,
            bottom: 4,
            left: 0,
            right: 0,
            background: "#df2027",
            zIndex: 20,
            borderRadius: "10px"
          }}
        /> */}
      </div>
    </>
  )
}

export default Reveal